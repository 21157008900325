import {RAILS_ENV} from '@tfx/common/constants.js'

const DISCOVERY_CALL_PUSH_KEY = 'discovery_call_push_sent'
const DISCOVERY_CALL_PUSH_DELAY = 15000

export default function () {
  if (RAILS_ENV !== 'production') return

  function sendMessage() {
    const visitorId = window.Intercom ? window.Intercom('getVisitorId') : null
    const sentAlready = window.localStorage ? window.localStorage.getItem(DISCOVERY_CALL_PUSH_KEY) : true
    if (visitorId && !sentAlready) {
      window.localStorage?.setItem(DISCOVERY_CALL_PUSH_KEY, '1')
      fetch('/discovery_call_push', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({visitor_id: visitorId})
      })
    }
  }

  setTimeout(sendMessage, DISCOVERY_CALL_PUSH_DELAY)
}
