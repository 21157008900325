import {Controller} from 'stimulus'

export default class extends Controller {
  static targets = ['menu', 'content', 'mobileMenuContent', 'parent', 'hamburger', 'link']

  connect() {
    document.addEventListener('click', this.handleClickOutside.bind(this))
    window.addEventListener('resize', this.checkScreenSize.bind(this))
    this.addLinkEventListeners()
    this.checkScreenSize()
  }

  disconnect() {
    document.removeEventListener('click', this.handleClickOutside.bind(this))
    window.removeEventListener('resize', this.checkScreenSize.bind(this))
    this.removeLinkEventListeners()
  }

  addLinkEventListeners() {
    this.linkTargets.forEach(link => {
      link.addEventListener('click', this.handleLinkClick.bind(this))
    })
  }

  removeLinkEventListeners() {
    this.linkTargets.forEach(link => {
      link.removeEventListener('click', this.handleLinkClick.bind(this))
    })
  }

  handleLinkClick(event) {
    this.closeDropdown()
    this.closeMobileMenu()
  }

  toggle(event) {
    event.stopPropagation()
    this.contentTarget.classList.toggle('tw-hidden')
    this.parentTarget.classList.toggle('header__menu-item--open')
  }

  toggleMobileMenu(event) {
    event.stopPropagation()
    const isActive = this.mobileMenuContentTarget.classList.toggle('active')
    this.hamburgerTarget.classList.toggle('active')
    document.body.style.overflow = isActive ? 'hidden' : ''
  }

  handleClickOutside(event) {
    if (!this.element.contains(event.target)) {
      this.closeDropdown()
    }
    if (this.mobileMenuContentTarget && !this.mobileMenuContentTarget.contains(event.target)) {
      this.closeMobileMenu()
    }
  }

  closeDropdown() {
    this.contentTarget.classList.add('tw-hidden')
    this.parentTarget.classList.remove('header__menu-item--open')
  }

  closeMobileMenu() {
    if (this.mobileMenuContentTarget.classList.contains('active')) {
      this.mobileMenuContentTarget.classList.remove('active')
      this.hamburgerTarget.classList.remove('active')
      document.body.style.overflow = ''
    }
  }

  checkScreenSize() {
    if (window.innerWidth > 1023) {
      this.closeMobileMenu()
    }
  }
}
