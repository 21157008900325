export const Cookies = {
  set(cname, cvalue) {
    const date = new Date()
    date.setTime(date.getTime() + 365 * 24 * 60 * 60 * 1000)
    const domain = window.location.hostname
      .replace(/^www\./, '')
      .replace(/^app\./, '')

    document.cookie = `${cname}=${cvalue};expires=${date.toUTCString()};path=/;domain=${domain};SameSite=Lax`
  },

  get(cname) {
    const name = cname + '='
    const ca = document.cookie.split(';')
    for (let i = 0; i < ca.length; i++) {
      let cookie = ca[i]
      while (cookie.charAt(0) === ' ') {
        cookie = cookie.substring(1)
      }
      if (cookie.indexOf(name) === 0) {
        return cookie.substring(name.length, cookie.length)
      }
    }
    return ''
  }
}
