import {Controller} from 'stimulus'
import {useWindowResize} from 'stimulus-use'

export default class extends Controller {
  connect() {
    this.createTocElement()
    this.initializeTocbot()

    this.documentClickListener = this.onDocumentClick.bind(this)
    document.addEventListener('click', this.documentClickListener)
  }

  disconnect() {
    if (this.tocbot) {
      this.tocbot.default.destroy()
    }
    if (this.tocEl) {
      this.tocEl.remove()
    }
    document.removeEventListener('click', this.documentClickListener)

    // Workaround to avoid exception in tocbot#updateFixedSidebarClass
    if (this.tocbot && this.tocbot.default && this.tocbot.default.options) {
      this.tocbot.default.options.positionFixedSelector = null
    }
  }

  createTocElement() {
    this.tocEl = document.createElement('div')
    this.tocEl.classList.add('toc')
    this.element.appendChild(this.tocEl)

    if (window.innerWidth > 1200) {
      const tocWrapper = document.createElement('div')
      tocWrapper.classList.add('toc-wrapper')
      tocWrapper.appendChild(this.tocEl)
      this.element.appendChild(tocWrapper)
    }
  }

  initializeTocbot() {
    const tocContentId = 'js-toc-content'
    this.element.setAttribute('id', tocContentId)

    import('tocbot').then(tocbot => {
      tocbot.default.init({
        tocElement: this.tocEl,
        contentSelector: '#' + tocContentId,
        headingSelector: '.toc-heading',
        tocSelector: '.toc-wrapper',
        includeHtml: true,
        hasInnerContainers: true,
        activeLinkClass: 'is-active-link',
        listClass: 'toc-list',
        listItemClass: 'toc-list-item',
        activeListItemClass: 'is-active-li',
        collapseDepth: 2,
        positionFixedSelector: '.toc',
        positionFixedClass: ' ',
        fixedSidebarOffset: 'auto',
        scrollSmooth: true,
        tocScrollOffset: 0,
        headingsOffset: 100,
        scrollSmoothOffset: -100
      })

      this.tocbot = tocbot
      this.observeTocChanges()

      useWindowResize(this)
    })
  }

  observeTocChanges() {
    const tocList = this.element.querySelector('.toc')
    if (tocList) {
      const observer = new MutationObserver(() => {
        if (tocList.children.length <= 0) {
          const tocWrapper = this.element.querySelector('.toc-wrapper')
          if (tocWrapper) {
            tocWrapper.style.display = 'none'
          }
        }
      })

      observer.observe(tocList, {childList: true})

      if (tocList.children.length <= 0) {
        const tocWrapper = this.element.querySelector('.toc-wrapper')
        if (tocWrapper) {
          tocWrapper.style.display = 'none'
        }
      }
    }
  }

  windowResize() {
    this.probeTocMobile()
  }

  probeTocMobile() {
    if (window.innerWidth < 1200) {
      if (!this.tocMobileEl) {
        this.tocMobileEl = document.createElement('div')
        this.tocMobileEl.className = 'toc-mobile'

        const tocList = this.tocEl.querySelector('.toc-list')
        if (tocList) {
          tocList.parentNode.insertBefore(this.tocMobileEl, tocList.nextSibling)
        }
      }
    } else {
      if (this.tocMobileEl) {
        this.tocMobileEl.remove()
        this.tocMobileEl = null
      }
    }
  }

  onDocumentClick(event) {
    if (!this.tocMobileEl) {
      return
    }

    if (event.target !== this.tocMobileEl) {
      this.tocEl.classList.remove('open')
    } else {
      this.tocEl.classList.toggle('open')
    }
  }
}
