// Please note, this module is also used by the tfx.tax. (app/javascript/tfx.tax/index.js)

import {Cookies} from '@tfx/common/modules/cookies'

const EXTERNAL_UTM_PARAMS_COOKIE = 'external_utm_params'
const EXTERNAL_REFERER_COOKIE = 'external_referer'

const saveReferrer = () => {
  const referrer = document.referrer
  const referrerDomain = referrer.match(/^(?:https?:\/\/)?(?:www\.)?([^:/?\n]+)/)
  const validReferrer = referrer !== '' &&
    !['taxesforexpats.com', 'tfx.tax', 'taxesforexpats.xyz', 'tfxtax.xyz', 'lvh.me']
      .some((domain) => referrerDomain[1].includes(domain))
  if (validReferrer) {
    Cookies.set(EXTERNAL_REFERER_COOKIE, referrer)
  }
}

const saveUtmParams = () => {
  const utmParams = window.location.search
    .replace(/^\?/, '')
    .split('&')
    .filter((param) => param.match(/^utm_/))
    .join('&')
  if (utmParams.length) {
    Cookies.set(EXTERNAL_UTM_PARAMS_COOKIE, utmParams)
  }
}

export default function () {
  const utmParams = Cookies.get(EXTERNAL_UTM_PARAMS_COOKIE)
  const referrer = Cookies.get(EXTERNAL_REFERER_COOKIE)

  if (!referrer) { saveReferrer() }
  if (!utmParams) { saveUtmParams() }
}
