import {Application as Stimulus} from 'stimulus'
import {definitionsFromContext} from 'stimulus/webpack-helpers'

let stimulus

export default function() {
  stimulus = Stimulus.start()
  stimulus.load(definitionsFromContext(require.context('tfx.tax/controllers', true, /.js$/)))
  stimulus.load(definitionsFromContext(require.context('../../../components/websites/tfx/components', true, /.js$/)))
}
