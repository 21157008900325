import {Controller} from 'stimulus'

export default class extends Controller {
  static targets = ['articles', 'readMoreButton']
  static values = {
    endpoint: String,
    pageCount: Number
  }

  connect() {
    this.page = 0
  }

  loadMore() {
    this.page++

    if (this.pageCountValue === this.page + 1) {
      this.readMoreButtonTarget.hidden = true
    }

    fetch(this.endpointValue + `?page=${this.page}`)
      .then(response => response.text())
      .then(html => {
        this.articlesTarget.innerHTML += html
      })
  }
}
