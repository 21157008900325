import '@splidejs/splide/css'
import Splide from '@splidejs/splide'
import {Controller} from 'stimulus'

export default class extends Controller {
  static targets = ['slider', 'frame', 'player']

  connect() {
    const isSm = window.matchMedia('(min-width: 640px)')

    this.slider = new Splide(
      this.sliderTarget,
      {
        classes: {
          pagination: 'tw-hidden',
          arrows: 'tw-hidden'
        },
        type: isSm.matches ? 'loop' : 'slide',
        focus: 'left',
        autoWidth: true,
        mediaQuery: 'min',
        padding: 16,
        perMove: 3,
        breakpoints: {
          640: {
            padding: 0
          }
        }
      }
    ).mount()

    this.onPopState = this.onPopState.bind(this)
    this.onKeyUp = this.onKeyUp.bind(this)
    window.addEventListener('popstate', this.onPopState)
    window.addEventListener('keyup', this.onKeyUp)

    this.onPopState()
  }

  disconnect() {
    window.removeEventListener('popstate', this.onPopState)
    window.removeEventListener('keyup', this.onKeyUp)

    this.closeVideoPlayer()
    this.slider.destroy(true)
  }

  onPopState() {
    if (window.location.hash.startsWith('#review-')) {
      const url = window.location.hash.replace('#review-', '')
      this.openVideoPlayer(url)
    } else {
      this.closeVideoPlayer()
    }
  }

  onKeyUp(event) {
    if (window.location.hash.startsWith('#review-') && event.key === 'Escape') {
      this.onClose()
    }
  }

  prev() {
    this.slider.go('<')
  }

  next() {
    this.slider.go('>')
  }

  onClose() {
    window.history.pushState(null, null, '#')

    this.closeVideoPlayer()
  }

  onOpen(event) {
    window.history.pushState(null, null, `#review-${event.params.url}`)

    this.openVideoPlayer(event.params.url)
  }

  closeVideoPlayer() {
    this.allowScroll(true)
    this.playerTarget?.classList.add('!tw-hidden')
    if (this.frameTarget) this.frameTarget.innerHTML = ''
  }

  openVideoPlayer(url) {
    this.allowScroll(false)
    this.playerTarget.classList.remove('!tw-hidden')
    const iframe = document.createElement('iframe')
    iframe.src = url
    iframe.className = 'video-player-modal__iframe'
    iframe.frameborder = '0'
    iframe.allow = 'autoplay; encrypted-media; gyroscope; picture-in-picture'
    iframe.allowfullscreen = true

    this.frameTarget.innerHTML = ''
    this.frameTarget.appendChild(iframe)
  }

  allowScroll(allow) {
    document.documentElement.style.overflow = allow ? 'auto' : 'hidden'
  }
}
