import {Controller} from 'stimulus'

export default class extends Controller {
  static classes = ['open']

  toggle(e) {
    e.preventDefault()
    this.element.classList.toggle(this.openClass)
  }
}
