import {Controller} from 'stimulus'

export default class extends Controller {
  connect() {
    if (!localStorage.getItem('cookie-banner-hide')) {
      this.element.classList.remove('tw-hidden')
    }
  }

  close() {
    this.element.remove()
    localStorage.setItem('cookie-banner-hide', true)
  }
}
