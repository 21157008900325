import '@splidejs/splide/css'
import Splide from '@splidejs/splide'
import {Controller} from 'stimulus'

export default class extends Controller {
  static targets = ['slider']

  connect() {
    this.slider = new Splide(
      this.sliderTarget,
      {
        classes: {
          pagination: 'splide__pagination text-slider__pagination',
          arrows: 'tw-hidden'
        },
        type: 'loop',
        focus: 0,
        autoWidth: true,
        mediaQuery: 'min',
        padding: 16,
        gap: 16,
        breakpoints: {
          640: {
            padding: 0,
            gap: 24
          }
        }
      }
    ).mount()
  }

  disconnect() {
    this.slider.destroy(true)
  }

  prev() {
    this.slider.go('<')
  }

  next() {
    this.slider.go('>')
  }
}
