import {Controller} from 'stimulus'

export default class extends Controller {
  static targets = ['checkbox']
  static values = {
    endpoint: String
  }

  timeZoneChanged(event) {
    this.timeZone = event.target.value
    this.loadCalendar()
  }

  timeFormatChanged() {
    this.format = this.checkboxTarget.checked ? '24' : ''
    this.loadCalendar()
  }

  onCalendarClick(event) {
    event.preventDefault()
    this.date = event.params.date
    this.loadCalendar()
  }

  loadCalendar() {
    fetch(this.url(this.date, this.timeZone, this.format))
      .then(response => response.text())
      .then(html => this.html2vue(html))
  }

  connect() {
    this.timeZone = new Intl.DateTimeFormat().resolvedOptions().timeZone
    this.loadCalendar()
  }

  disconnect() {
    if (this.vue) {
      this.vue.unmount()
    }
  }

  url(date, timezone, format) {
    const params = `?date=${date || ''}&consultation_timezone=${encodeURIComponent(timezone || '')}&consultation_format=${format || ''}`
    return this.endpointValue + params
  }

  html2vue(html) {
    this.element.innerHTML = html
  }
}
