export default function () {
  const isMobile = window.screen.width * window.devicePixelRatio < 1024

  if (!isMobile) {
    import('aos/dist/aos.css')
    import('aos').then(oas => oas.default.init({
      disable: 'mobile',
      once: true
    }))
  }
}
