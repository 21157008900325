import {Controller} from 'stimulus'

export default class extends Controller {
  static targets = ['fixedButton']

  connect() {
    this.checkButtonVisibility()
    window.addEventListener('scroll', () => this.checkButtonVisibility())
    window.addEventListener('resize', () => this.checkButtonVisibility())
  }

  checkButtonVisibility() {
    const staticButton = document.getElementById('static-btn')
    const footer = document.getElementById('contact-us')
    const cookieBanner = document.querySelector('.cookie-banner')

    if (!staticButton || !footer) {
      this.fixedButtonTarget.classList.add('tw-hidden')
      return
    }

    const size = staticButton.getBoundingClientRect()
    const footerRect = footer.getBoundingClientRect()
    const isVisible = size.top >= 0 && size.bottom <= (window.innerHeight || document.documentElement.clientHeight)
    const footerVisible = footerRect.top < window.innerHeight

    if (isVisible || footerVisible) {
      this.fixedButtonTarget.classList.add('tw-hidden')
    } else {
      this.fixedButtonTarget.classList.remove('tw-hidden')
    }

    if (footerVisible) {
      const bottomSpace = window.innerHeight - footerRect.top
      this.fixedButtonTarget.style.bottom = `${bottomSpace}px`
    } else {
      this.fixedButtonTarget.style.bottom = this.getResponsiveBottomIndent()
    }

    if (cookieBanner) {
      cookieBanner.style.bottom = '8px'
    }

    if (cookieBanner && window.innerWidth < 1023) {
      if (!this.fixedButtonTarget.classList.contains('tw-hidden')) {
        cookieBanner.style.bottom = '124px'
      } else {
        cookieBanner.style.bottom = '8px'
      }

      const cookieBannerRect = cookieBanner.getBoundingClientRect()
      if (footerRect.top <= cookieBannerRect.bottom) {
        cookieBanner.style.bottom = '8px'
        this.fixedButtonTarget.style.bottom = '8px'
      }
    } else if (cookieBanner) {
      cookieBanner.style.bottom = ''
    }
  }

  getResponsiveBottomIndent() {
    if (window.innerWidth < 1023) {
      return '0'
    } else {
      return '12px'
    }
  }
}
