import {RAILS_ENV, GA_TRACKER_ID, TFX_DOMAIN, EXPATS_DOMAIN} from 'tfx-common/constants.js'
import {Cookies} from '@tfx/common/modules/cookies'

export function googleTagManager() {
  setupEvents()

  if (!['staging', 'production'].includes(RAILS_ENV)) {
    return
  }

  restoreGaSessionFromCookies()

  const tagManagerId = RAILS_ENV === 'production' ? 'GTM-M9TN5DX' : 'GTM-WPT8CH49'

  setTimeout(
    () => {
      (function (w, d, s, l, i) {
        w[l] = w[l] || []
        w[l].push({
          'gtm.start': new Date().getTime(),
          event: 'gtm.js'
        })
        const f = d.getElementsByTagName(s)[0]
        const j = d.createElement(s)
        const dl = l !== 'dataLayer' ? '&l=' + l : ''
        j.async = true
        j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl
        f.parentNode.insertBefore(j, f)
      })(window, document, 'script', 'dataLayer', tagManagerId)
    },
    1000
  )
}

export function setGaUserId(userId) {
  // eslint-disable-next-line
  window.ga=window.ga||function(){(ga.q=ga.q||[]).push(arguments)};ga.l= +new Date;
  window.ga('set', 'userId', `${userId}`)
}

export function intercomWidget(intercomSettings) {
  window.intercomSettings = intercomSettings

  const ic = window.Intercom
  if (typeof window.Intercom === 'function') {
    ic('reattach_activator')
    ic('update', window.intercomSettings)
  } else {
    // eslint-disable-next-line no-var
    var i = function (...args) {
      i.c(args)
    }
    i.q = []
    i.c = function (args) {
      i.q.push(args)
    }
    window.Intercom = i

    const script = document.createElement('script')
    script.src = 'https://widget.intercom.io/widget/' + window.intercomSettings.app_id
    script.async = true
    document.getElementsByTagName('head')[0].appendChild(script)
  }
}

function gtag() {
  window.dataLayer = window.dataLayer || []
  window.dataLayer.push(arguments)
}
window.gtag = gtag

function restoreGaSessionFromCookies() {
  const gaClientId = Cookies.get('tfx_ga_client_id')
  const gaSessionId = Cookies.get('tfx_ga_session_id')

  if (gaClientId && gaSessionId) {
    gtag('config', GA_TRACKER_ID, {
      client_id: gaClientId,
      session_id: gaSessionId
    })
  }
}

function setupEvents() {
  window.tfxEvent = function (eventName, eventParams) {
    const clientNiche = (function () {
      switch (true) {
        case (!!document.querySelector('body').dataset.clientNiche):
          return document.querySelector('body').dataset.clientNiche
        case (location.hostname === TFX_DOMAIN):
          return 'business-owners'
        case (location.hostname === EXPATS_DOMAIN):
          return 'expats'
        default:
          return ''
      }
    })()

    if (!clientNiche) return

    const correctedEventName = eventName.replace(/^ga_/, clientNiche === 'expats' ? 'tfe_' : 'tfx_')

    if (['staging', 'development'].includes(RAILS_ENV)) {
      // eslint-disable-next-line no-console
      console.log('tfxEvent', correctedEventName, eventParams)
    }

    if (window.gtag) {
      gtag('event', correctedEventName, eventParams)
    }
  }

  function gtagClick({target}) {
    const targetData = target.dataset
    if (targetData.eventName) {
      const eventData = {
        name: targetData.eventName,
        params: {
          url: window.location.href
        }
      }
      Object.keys(targetData).filter((key) => key.startsWith('eventParam')).forEach((key) => {
        eventData.params[key.slice(10).toLowerCase()] = targetData[key]
      })
      window.tfxEvent(eventData.name, eventData.params)
    }
  }

  document.addEventListener('click', gtagClick, true)
}

export function fbPixel() {
  if (RAILS_ENV !== 'production') return

  let pixelEnabled

  if (Cookies.get('tfx_fb_pixel_enabled') === '1') {
    pixelEnabled = true
  } else {
    const params = new URLSearchParams(window.location.search)
    if (params.get('utm_source') === 'facebook.com' && params.get('utm_medium') === 'cpc') {
      Cookies.set('tfx_fb_pixel_enabled', 1)
      pixelEnabled = true
    }
  }

  if (!pixelEnabled) return

  // eslint-disable-next-line no-unused-expressions
  !(function (f, b, e, v, n, t, s) {
    if (f.fbq) return; n = f.fbq = function () {
      n.callMethod
        ? n.callMethod.apply(n, arguments)
        : n.queue.push(arguments)
    }
    if (!f._fbq)f._fbq = n; n.push = n; n.loaded = !0; n.version = '2.0'
    n.queue = []; t = b.createElement(e); t.async = !0
    t.src = v; s = b.getElementsByTagName(e)[0]
    s.parentNode.insertBefore(t, s)
  }(window, document, 'script', 'https://connect.facebook.net/en_US/fbevents.js'))

  window.fbq('init', '1056428568755182')
  window.fbq('track', 'PageView')
}
