import {Controller} from 'stimulus'

export default class extends Controller {
  static targets = ['tab', 'content']
  static values = {
    activeClass: {
      type: String,
      default: 'who-we-help__tabs-item--active'
    }
  }

  connect() {
    requestAnimationFrame(() => {
      if (window.innerWidth > 1024) {
        this.equalizeContentHeights()
      }
    })
  }

  switchTab(event) {
    const tabName = event.target.dataset.tabName
    this.tabTargets.forEach((tab, index) => {
      const content = this.contentTargets[index]
      if (tab.dataset.tabName === tabName) {
        tab.classList.add(this.activeClassValue)
        content.classList.remove('tw-hidden')
        content.classList.add('tw-block')
      } else {
        tab.classList.remove(this.activeClassValue)
        content.classList.add('tw-hidden')
        content.classList.remove('tw-block')
      }
    })

    if (window.innerWidth > 1024) {
      this.equalizeContentHeights()
    }
  }

  equalizeContentHeights() {
    let maxHeight = 0

    this.contentTargets.forEach((content) => {
      content.classList.remove('tw-hidden')
      maxHeight = Math.max(maxHeight, content.offsetHeight)
    })

    this.contentTargets.forEach((content) => {
      content.style.height = `${maxHeight}px`
    })

    this.contentTargets.forEach((content, index) => {
      if (!this.tabTargets[index].classList.contains(this.activeClassValue)) {
        content.classList.add('tw-hidden')
      }
    })
  }
}
