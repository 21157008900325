import {googleTagManager, setGaUserId, intercomWidget, fbPixel} from '@tfx/common/modules/externals-scripts'
import {RAILS_ENV} from '@tfx/common/constants.js'

export default function () {
  googleTagManager()
  fbPixel()

  if (!['staging', 'production'].includes(RAILS_ENV)) {
    return
  }

  fetch('/geocitylocation_clientdata')
    .then(response => response.json())
    .then(json => {
      if (json.clientData.user_id) {
        setGaUserId(json.clientData.user_id)
      }
      if (RAILS_ENV === 'production' && json.clientData.intercom) {
        intercomWidget(json.clientData.intercom)
      }
    })
}
